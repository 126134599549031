const NAME_WEBSITE_ROLES_MANARER = {
  user: "User Screen",
  alarm: "Alarm Screen",
  asset: "Asset Screen",
  install: "Install Screen",
  inventory: "Inventory Screen",
  multi_site: "Multi Site",
  single_site: "Single Site",
  data_analytic: "Data Analytic",
};

const NAME_APP_ROLES_MANAGER = {
  app_alarm: "App Alarm",
  app_value: "App Value",
  app_device: "App Device",
  app_energy: "App Energy",
};

export { NAME_WEBSITE_ROLES_MANARER, NAME_APP_ROLES_MANAGER };
