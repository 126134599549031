<template>
  <div class="not-found">
    <div class="image"></div>
    <span>404</span>
    <span>Sorry, the page you visited does not exist.</span>
    <ButtonComponent :onClick="handleBackHome" label="Back Home" />
  </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  name: 'not-found-layout',
  components: {
    ButtonComponent,
  },
  methods: {
    handleBackHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100vh;

  span {
    font-size: 24px;
    color: #ffffffe5;
  }
}
</style>
