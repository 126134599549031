<template>
  <div class="login-page">
    <div class="title text-center">Panasonic</div>
    <form @submit.prevent="onSubmit" v-validate>
      <div class="form-username">
        <InputComponent
          type="text"
          name="username"
          v-model="username"
          v-validate="'required'"
          fieldLabel="User Name"
          :outlined="false"
          placeholder="Enter username"
          :errorMessage="errors.first('username')"
        />
      </div>
      <div class="form-password">
        <InputComponent
          type="password"
          name="password"
          v-model="password"
          v-validate="'required'"
          fieldLabel="Password"
          :outlined="false"
          placeholder="Enter password"
          :errorMessage="errors.first('password')"
        />
      </div>
      <ButtonComponent
        class="login-button"
        type="submit"
        label="Submit"
      ></ButtonComponent>
    </form>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputComponent from "@/components/InputComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "login-page",
  data() {
    return {
      username: "",
      password: "",
    };
  },

  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("common", ["getProfileUser"]),
    async onSubmit() {
      const result = await this.$validator.validateAll();
      if (result) {
        const { data, success } = await this.login({
          username: this.username,
          password: this.password,
        });

        if (success) {
          this.$toast.success('Login successful.', {
            position: 'bottom-left'
          });
          localStorage.setItem('access_token', data.token);
          const response = await this.getProfileUser();
          const accessibleMenus = response.data.user_group.accessible_menus.user.view;
          const roleUser = response.data.user_group.id;
          if(accessibleMenus || roleUser === 2) {
            this.$router.push('/user-manager');
          } else {
            this.$router.push('/login');
          }
        }
      }
    },
  },

  components: {
    ButtonComponent,
    InputComponent,
  },
};
</script>

<style scoped>
.login-page {
  border: 3px solid #2c2c2e;
  width: 500px;
  height: 350px;
  margin: 0 auto;
  padding: 10px 10px;
}

.title {
  font-size: 24px;
  padding-bottom: 15px;
  color: #ffffffe5;
}

.validate {
  display: block;
  width: 300px;
  height: 30px;
}

.form-username {
  margin-bottom: 20px;
}

.form-password {
  margin-bottom: 20px;
}

.login-button {
  display: block;
  margin: 0 auto;
}

label {
  color: #fff;
}

input {
  height: 40px;
  width: 350px;
  border: 1px solid #2c2c2e;
  border-radius: 10px;
  background-color: #2c2c2e;
  color: #fff;
}
</style>
