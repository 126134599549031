import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VeeValidate from 'vee-validate';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import VueToast from 'vue-toast-notification';

Vue.use(VueToast);

Vue.use(Vuetify);
const config = {
  errorBagName: 'errors',
  fieldsBagName: 'fields',
  delay: 0,
  locale: 'en',
  dictionary: {
    en: {
      messages: {
        required: (field) => `${field} is required.`,
        email: 'Email address is invalid format',
      }
    }
  },
  strict: true,
  classes: false,
  classNames: {
    touched: 'touched',
    untouched: 'untouched',
    valid: 'valid',
    invalid: 'invalid',
    pristine: 'pristine',
    dirty: 'dirty'
  },
  events: 'input|blur',
  inject: true,
  validity: false,
  aria: true
};

Vue.use(VeeValidate, config);

const vuetify= new Vuetify({  })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
