<template>
  <div>
    <HeaderLayout :accessibleMenus="getterAccessibleMenus" :getterRole="getterRole"/>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderLayout from '@/layout/HeaderLayout.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'home-layout',
  components: {
    HeaderLayout,
  },
  created() {
    this.fetchDataProfile()
  },
  methods: {
    ...mapActions("common", {
      getProfileUser: "getProfileUser",
    }), 
    async fetchDataProfile() {
       await this.getProfileUser();
    },
  },
  computed: {
    ...mapGetters("common", {
      getterAccessibleMenus: "getterAccessibleMenus",
      getterRole: "getterRole"
    }),

  },
};
</script>

<style scoped lang="scss">
.container {
  flex: 1;
  padding: 0;
}

@media (min-width: 1366px) {
  .container {
    max-width: none !important;
    width: calc(100% - 80px);
  }
}
</style>
