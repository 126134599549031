<template>
  <div class="form-login">
    <LoginComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import LoginComponent from '@/components/LoginComponent.vue'

export default {
  name: 'BlogDetailView',
  components: {
    LoginComponent
  }
}
</script>

<style scoped>
  .form-login {
    display: flex;
    justify-content: center;
    padding-top: 50px
  }
</style>
