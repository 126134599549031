<template>
  <div>
    <div class="user-manage">
      <div class="display-flex justify-between flex-wrap mb-40">
        <div class="display-flex col-12 col-lg-6">
          <div
            v-for="(item, index) in userStatus"
            :key="index"
            class="display-flex col-6 user-unlocked align-center"
          >
            <img :src="item.icon" class="icon-status display-flex align-center" />
            <div>
              <div class="status-user">
                <div class="item-count">
                  {{ item.number }}
                </div>
                <div class="item-status">
                  {{ item.status }}
                </div>
              </div>
              <div class="item-unit">
                {{ item.unit }}
              </div>
            </div>
          </div>
        </div>
        <div class="display-flex col-12 col-lg-6 filter-container">
          <ButtonComponent
            label="Add New User"
            :width="181"
            :height="isSmallScreen ? 40 : 56"
            :onClick="handleAddEdit"
            :outlined="false"
            :disabled="!getterAccessibleMenus?.user.action"
          />
          <InputComponent
            class="input-search"
            :showLabel="false"
            v-model="search"
            :height="56"
            @enter="handleSearch"
            placeholder="Search..."
            iconInput="mdi mdi-magnify"
          />
        </div>
      </div>
      <TableComponent
        :items="userManager"
        :headers="headers"
        :page="page"
        :total="total"
        :disabled="!getterAccessibleMenus?.user.action"
        @view="handleView"
        @edit="handleAddEdit"
        @delete="handleDelete"
        @on-change-page="onChangePage"
        @on-sort="handleSort"
      />
    </div>
    <ModalComponent
      v-if="showModalDetail.isShow"
      :width="477"
      titleHeader="User Information"
      :widthButtonFooter="397"
      aciontCenter
      :onModalCancelEvent="handleTurnOffModalDetail"
      :isShowBtnSave="false"
    >
      <template v-slot:action>
        <div class="icon-action-title">
          <img :class="{ 'disabled-icon': !getterAccessibleMenus?.user.action }" :src="ICON_EDIT"  @click="!getterAccessibleMenus?.user.action ? null : handleAddEdit(showModalDetail.data)"/>
          <img :class="{ 'disabled-icon': !getterAccessibleMenus?.user.action }" :src="ICON_DELETE" @click="!getterAccessibleMenus?.user.action ? null : handleDelete(showModalDetail.data)" />
        </div>
      </template>
      <div class="user-infomation-detail">
        <div class="infomation-detail">
          <div class="title-detail">USER ID</div>
          <div class="value-detail">{{ showModalDetail.data.id }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">USER NAME</div>
          <div class="value-detail">{{ showModalDetail.data.username }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">EMAIL</div>
          <div class="value-detail">{{ showModalDetail.data.email }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">USER GROUP</div>
          <div class="value-detail">{{ showModalDetail.data.user_group }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">SITE</div>
          <div class="value-detail">
            {{
              showModalDetail.data.user_group.includes('Superadmin')
                ? 'All Sites'
                : showModalDetail.data.sitename.join(', ')
            }}
          </div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">STATUS</div>
          <div class="value-detail">{{ showModalDetail.data.status }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">LAST LOGIN</div>
          <div class="value-detail">{{ showModalDetail.data.last_login }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">LOGIN FAIL</div>
          <div class="value-detail">{{ showModalDetail.data.login_fail }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">LANGUAGE</div>
          <div class="value-detail">{{ showModalDetail.data.language }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">IS DASK MODE</div>
          <div class="value-detail">{{ showModalDetail.data.is_dark_mode }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">CREAT AT</div>
          <div class="value-detail">{{ showModalDetail.data.created_at }}</div>
        </div>
        <div class="infomation-detail">
          <div class="title-detail">UPDATE AT</div>
          <div class="value-detail">{{ showModalDetail.data.updated_at }}</div>
        </div>
      </div>
    </ModalComponent>
    <ModalComponent
      v-if="showModalAddSuccess"
      :width="672"
      titleHeader="New user added successfully"
      btnCancel="MANAGE USERS"
      btnSave="ADD NEW USER"
      :widthButtonFooter="288"
      :onModalCancelEvent="handleTurnOffModalAddSuccess"
      :onModalSaveEvent="handleBtnAddModalAddSuccess"
    >
      <div class="user-infomation-add-success">
        <div class="notify-success">
          <div class="notify">
            Congratulations! The new user has been successfully added to the
            system.
          </div>
          <div class="notify">New user information:</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Username:</div>
          <div class="value-info">{{ newUserInfomation?.username }}</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Email:</div>
          <div class="value-info">{{ newUserInfomation?.email }}</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">User Group:</div>
          <div class="value-info">{{ newUserInfomation?.user_group?.name }}</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Site:</div>
          <div class="value-info">
            {{newUserInfomation?.user_group.id === 2 ? "All Sites" : displayNames(newUserInfomation?.sites) }}
          </div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Status:</div>
          <div class="value-info">{{ newUserInfomation?.status }}</div>
        </div>
        <div class="notify">
          You can go back to the "Manage Users" page to view the list of users
          or continue adding new users.
        </div>
      </div>
    </ModalComponent>
    <ModalComponent
      v-if="showDeleteConfirm.isShow"
      titleHeader="Delete User"
      :widthButtonFooter="288"
      :onModalCancelEvent="handleOffConfirmDelete"
      :onModalSaveEvent="handleDeleteSuccess"
      btnSave="CONFIRM"
    >
      <div class="delete-user-confirm">
        <div class="notify">Are you sure you want to delete this user?</div>
        <div class="infomation-confirm">
          <div class="title-info">Username:</div>
          <div class="value-info">{{ showDeleteConfirm.data.username }}</div>
        </div>
        <div class="infomation-confirm">
          <div class="title-info">Email:</div>
          <div class="value-info">{{ showDeleteConfirm.data.email }}</div>
        </div>
        <div class="infomation-confirm">
          <div class="title-info">User Group:</div>
          <div class="value-info">{{showDeleteConfirm.data.user_group}}</div>
        </div>
      </div>
    </ModalComponent>
    <ModalComponent
      v-if="showDeleteSuccess"
      titleHeader="User Deleted Successfully"
      :widthButtonFooter="257"
      :onModalCancelEvent="handleTurnOffDeleteSuccess"
      :isShowBtnSave="false"
      btnCancel="CLOSE"
      actionRight
    >
      <div class="notify">
        The user has been successfully deleted from the system.
      </div>
    </ModalComponent>

    <!-- Edit Success -->
    <ModalComponent
      v-if="showModal.isShow"
      :width="750"
      :titleHeader="showModal.id ? 'Edit User' : 'Add New User'"
      :onModalSaveEvent="onSubmit"
      :onModalCancelEvent="showModal.id ? handleTurnOffModalEdit: handleTurnOffModalAdd"
      :widthButtonFooter="327"
    >
      <form class="form-edit-add-user" @submit.prevent="onSubmit" v-validate>
        <div class="form-user">
          <InputComponent
            type="text"
            name="Username"
            v-model="username"
            v-validate="{
              required: true,
              number_of_characters: { fieldType: 'Username' },
              validUserName,
            }"
            fieldLabel="User Name"
            :outlined="false"
            placeholder="Enter User Name"
            :errorMessage="errors.first('Username')"
          />
        </div>
        <div class="form-user">
          <SelectComponent
            v-if="showModal.id"
            :items="['Unlocked', 'Locked']"
            fieldLabel="Status"
            name="status"
            v-bind="$attrs"
            :outlined="false"
            placeholder="Select Status"
            :fieldRequired="false"
            v-model="status"
            v-validate="''"
            :errorMessage="errors.first('status')"
          />
        </div>
        <div class="form-user password-random">
          <div class="field" :class="{'field-error-input': errors.first('Password')}">
            <label class="label">Password</label>
            <label v-if="!showModal.id" class="symbol-required">*</label>
          </div>
          <div class="form-password">
            <v-text-field
            dense
            solo
            name="Password"
            v-model="password"
            :height='40'
            v-validate="{
              required: showModal.id ? false : true,
              regex: /^\S*$/,
              number_of_characters: { fieldType: 'Password' },
              validPassword
            }"
            placeholder="Enter Password"
            class="input-component"
            :class="{'error-input': errors.first('Password')}"
            />
            <span class="error-message">{{ errors.first('Password') }}</span>
          </div>
          <ButtonComponent
            class="btn-random"
            :width="154"
            :height="40"
            :fontSize="13"
            label="Random Password"
            :outlined="false"
            :onClick="handlerandomPassword"
          />
        </div>
        <div class="form-user">
          <InputComponent
            name="Email"
            v-model="email"
            v-validate="{
              required: true,
              validEmail,
              maxValidEmail
            }"
            fieldLabel="Email"
            :outlined="false"
            placeholder="Enter Email"
            :errorMessage="errors.first('Email')"
          />
        </div>
        <div class="form-user">
          <SelectComponent
            :items="listUserGroup?.map((item) => item.name)"
            fieldLabel="User Group"
            name="User group"
            v-bind="$attrs"
            :outlined="false"
            placeholder="Select User Group"
            v-model="userGroup"
            v-validate="'required'"
            :errorMessage="errors.first('User group')"
          />
        </div>
        <div v-if="userGroup === 'Superadmin'" class="form-user">
          <AutocompleteComponent
            :items="listSites"
            fieldLabel="Site"
            name="Site"
            :itemText="'name'"
            :itemValue="'id'"
            :outlined="false"
            :placeholder="'All Sites'"
            v-model="site"
            v-validate="''"
            :errorMessage="errors.first('Site')"
            disabled
            :class="userGroup === 'Superadmin' && 'hidden-value'"
          />
        </div>
        <div v-if="userGroup !== 'Superadmin'" class="form-user">
          <AutocompleteComponent
            :items="listSites"
            fieldLabel="Site"
            name="Site"
            :itemText="'name'"
            :itemValue="'id'"
            :outlined="false"
            :placeholder="site?.length ? '' : 'Select Site'"
            v-model="site"
            v-validate="'required'"
            :errorMessage="errors.first('Site')"
          />
        </div>
      </form>
    </ModalComponent>
    <ModalComponent
      v-if="showModalEditSuccess"
      :width="672"
      titleHeader="Edit user successfully"
      :widthButtonFooter="288"
      :isShowBtnSave="false"
      actionRight
      btnCancel="CLOSE"
      :onModalCancelEvent="handleTurnOffModalEditSuccess">
      <div class="user-infomation-edit-success">
        <div class="notify-success">
          <div class="notify">
            The user has been successfully edited from the system.
          </div>
          <div class="notify">User information:</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Username:</div>
          <div class="value-info">{{ newUserInfomation?.username }}</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Email:</div>
          <div class="value-info">{{ newUserInfomation?.email }}</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">User Group:</div>
          <div class="value-info">{{ newUserInfomation?.user_group?.name }}</div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Site:</div>
          <div class="value-info">
            {{newUserInfomation?.user_group.id === 2 ? "All Sites" : displayNames(newUserInfomation?.sites)}}
          </div>
        </div>
        <div class="infomation-success">
          <div class="title-info">Status:</div>
          <div class="value-info">{{ newUserInfomation?.status === 'locked' ? 'Locked' : 'Unlocked' }}</div>
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
  ICON_ACTIVE,
  ICON_INACTIVE,
  ICON_EDIT,
  ICON_DELETE,
} from "@/theme/image";
import { generateRandomPassword } from "@/utils/common";

import TableComponent from "@/components/TableComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputComponent from "@/components/InputComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import SelectComponent from "@/components/SelectComponent.vue";
import AutocompleteComponent from "@/components/AutocompleteComponent.vue";
import { Validator } from 'vee-validate';

Validator.extend('number_of_characters', {
  getMessage: field => {
    if (field === 'Username') {
      return 'The ' + field.toLowerCase() + ' field must be 3 to 20 characters.'
    }
    if (field === 'Password') {
      return 'The ' + field.toLowerCase() + ' field must be 8 to 32 characters.'
    }
    // Thông báo mặc định
    return 'The ' + field.toLowerCase() + ' does not meet the character requirements.';
  },
  validate: (value, args) => {
    const fieldType = args.fieldType || 'default';
    if (fieldType === 'Username' && (value.length < 3 || value.length > 20)) {
      return {
        valid: false
      };
    }
    if (fieldType === 'Password' && (value.length < 8 || value.length > 32)) {
      return {
        valid: false
      };
    }
    return {
      valid: true
    };
  }
});
Validator.extend('validUserName', {
  getMessage: field => 'The '+ field.toLowerCase() + ' field can only include numbers, letters, and not contain special characters.',
  validate: value => {
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return {
        valid: false
      };
    }
    return {
      valid: true
    };
  }
});
Validator.extend('validPassword', {
  getMessage: field => field + ' must contain at least one lowercase letter, one uppercase letter, one number and one special character.',
  validate: value => {
    const hasletter = /[a-z]/.test(value); 
    const hasUppercase = /[A-Z]/.test(value); 
    const hasNumber = /[0-9]/.test(value); 
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(value);

    if (!hasUppercase || !hasNumber || !hasSpecialCharacter || !hasletter) {
      return {
          valid: false
      };
    }
    return {
      valid: true
    };
  }
});

Validator.extend('maxValidEmail', {
  getMessage: field => 'The '+field.toLowerCase() + ' must not exceed 255 character',
  validate: value => {
    if (value.length > 255) {
      return {
        valid: false
      };
    }
    return {
      valid: true
    };
  }
});
Validator.extend('validEmail', {
  getMessage: field => 'The '+field.toLowerCase() + ' is invalid.',
  validate: value => {
    const validEmail = /^[a-z0-9]+([/.\-_]?[a-z0-9]+)*([+][a-z0-9]+)?@[a-z0-9]+([/.\-_]?[a-z0-9]+)*(\.[a-z0-9]{2,})+$/i.test(value); 
    if (!validEmail) {
      return {
          valid: false
      };
    }
    return {
      valid: true
    };
  }
});
export default {
  name: "UserManageView",
  components: {
    TableComponent,
    ButtonComponent,
    InputComponent,
    ModalComponent,
    SelectComponent,
    AutocompleteComponent,
  },
  data() {
    return {
      username: "",
      password: "",
      email: "",
      userGroup: "",
      site: [],
      status: "",
      idEdit: "",
      data_sort: "",
      data_sort_filter:"",
      newUserInfomation: {},
      showModal:{
        isShow: false,
        data: null,
        id: null,
      },
      showModalDetail: {
        isShow: false,
        data: null,
      },
      showModalAddSuccess: false,
      showDeleteConfirm: {
        isShow: false,
        data: null,
      },
      showDeleteSuccess: false,
      showModalEditSuccess: false,
      key: "",
      modalVisible: false,
      userStatus: [],
      items: [],
      headers: [
        { text: "User ID", value: "id", width: 110, divider: true, sortable: true },
        { text: "Username", value: "username", width: 150, divider: true },
        { text: "Email", value: "email", width: 200, divider: true },
        { text: "Site Name", value: "allSite", width: 280, sortable: false, divider: true },
        { text: "User Group", value: "user_group", width: 180, divider: true },
        { text: "Status", value: "status", width: 150, divider: true },
        { text: "Last Login", value: "last_login", width: 160, divider: true },
        { text: "Login Fail", value: "login_fail", width: 120, sortable: true, divider: true },
        { text: "Language", value: "language", width: 100, sortable: true, divider: true },
        { text: "Create At", value: "created_at", width: 170, divider: true },
        { text: "Actions", value: "actions", width: 120, sortable: false },
      ],
      search: "",
      page: 1,
      total: null,
      isSmallScreen: window.innerWidth < 1440,
      ICON_EDIT,
      ICON_DELETE,
    };
  },
  created() {
    window.addEventListener("resizeButton", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.fetchData();
    this.fetchDataUserGroup();
    this.fetchDataSites();
  },
  computed: {
    ...mapState('userManager',['userManager']),
    ...mapGetters("userManager", {
      userManager: "getterUserManager",
      listUserGroup: "getterUserGroup",
      listSites: "getterSites",
    }),
    ...mapGetters("common", {
      getterAccessibleMenus: "getterAccessibleMenus",
    }),
    displayNames: function () {
      return function (data) {
        return data.map(item => item.name).join(', ');
      }
    },
  },
  methods: {
    ...mapActions("userManager", {
      getListUser: "getListUser",
      getListUserGroup: "getListUserGroup",
      getListSites: "getListSites",
      deleteUser: 'deleteUser',
      addUser: 'addUser',
      editUser: 'editUser'
    }),
    ...mapActions("common", ["getProfileUser"]),
    async fetchData(param) {
      const res = await this.getListUser({
        page: this.page,
        per_page: 10,
        sort: this.data_sort,
        sort_filter: this.data_sort_filter,
        txt_search: this.search,
        ...param,
      });
      this.total = res?.data.total;

      this.userStatus = [
        {
          icon: ICON_ACTIVE,
          number: res?.data.unlocked_users,
          status: "Unlocked",
          unit: "Users",
        },
        {
          icon: ICON_INACTIVE,
          number: res?.data.locked_users,
          status: "Locked",
          unit: "Users",
        },
      ];
      return res;
    },
    handlerandomPassword() {
      const randomPassword = generateRandomPassword(12, 20);
      this.password = randomPassword
    },
    async fetchDataUserGroup(param) {
      await this.getListUserGroup(param);
    },
    async fetchDataSites(param) {
      await this.getListSites(param);
    },
    async onSubmit() {
      if (this.showModal.id) {
        const result = await this.$validator.validateAll();
        if (result) {
          const dataGroupSelect = this.listUserGroup?.find(
            (item) => item.name === this.userGroup
          );
          const res = await this.editUser({
            username: this.username?.trim(),
            status: this.status === 'Unlocked' ? 'unlocked' : 'locked',
            password: this.password,
            email: this.email?.trim(),
            user_group_id: dataGroupSelect?.id,
            site_id: dataGroupSelect?.id !== 2 ? this.site : [0],
            id: this.idEdit,
          });
          if (res?.success === true) {
            await this.getProfileUser();
            const response = await this.fetchData();
            this.newUserInfomation = res.data;
            this.handleTurnOffModalEdit();
            this.handleTurnOffModalDetail();
            if (response.success) {
              this.handleTurnOnModalEditSuccess();
            }
          }
        }
      } else {
        const result = await this.$validator.validateAll();
        if (result) {
          const dataGroupSelect = this.listUserGroup?.find(
            (item) => item.name === this.userGroup
          );
          const res = await this.addUser({
            username: this.username?.trim(),
            password: this.password,
            email: this.email?.trim(),
            user_group_id: dataGroupSelect?.id,
            site_id: dataGroupSelect?.id !== 2 ? this.site : [0],
          });
          if (res?.success === true) {
            const response = await this.fetchData();
            this.newUserInfomation = res.data;
            this.handleTurnOffModalAdd();
            if (response.success) {
              this.handleTurnOnModalAddSuccess();
            }
          }
        }
      }
    },
    handleResize() {
      this.isSmallScreen = window.innerWidth < 1440;
    },
    handleView(item) {
      this.showModalDetail = {
        isShow: true,
        data: item,
      };
      this.newUserInfomation = item;
    },
    handleAddEdit(item) {
      if(item) {
        const listIdSitename = this.listSites?.filter(site => item.sitename.includes(site.name)).map(it => it.id)
        this.username = item.username;
        this.status = item.status === "Unlocked" ? "Unlocked" : "Locked";
        this.password = "";
        this.email = item.email;
        this.userGroup = item.user_group;
        this.site = listIdSitename;
        this.idEdit=item.id;
        this.showModal = {
          isShow: true,
          data: item,
          id: item.id,
        };
      }else{
        this.username = "";
        this.status = "";
        this.password = "";
        this.email = "";
        this.userGroup = "";
        this.site = [];
        this.showModal = {
          isShow: true,
          data: null,
        };
      }
    },
    handleDelete(item) {
      this.showDeleteConfirm = {
        isShow: true,
        data: item,
      };
    },
    handleOffConfirmDelete() {
      this.showDeleteConfirm = {
        isShow: false,
        data: null,
      };
    },
    async handleDeleteSuccess() {
      const res = await this.deleteUser(this.showDeleteConfirm.data.id);
      if (res.data.success) {
        const response = await this.fetchData();
        if(response.success) {
          this.handleOffConfirmDelete();
          this.handleOffConfirmDelete();
          this.handleTurnOffModalDetail();
          this.showDeleteSuccess = true;
        }
      }
    },
    handleTurnOffDeleteSuccess() {
      this.showDeleteSuccess = false;
    },
    
    handleTurnOffModalAdd() {
      this.showModal = {
        isShow: false,
        data: null,
        id: null,
      };
    },
    handleTurnOffModalDetail() {
      this.showModalDetail = {
        isShow: false,
        data: null,
      };
    },
    handleTurnOnModalAddSuccess() {
      this.showModalAddSuccess = true;
    },
    handleTurnOffModalAddSuccess() {
      this.showModalAddSuccess = false;
    },
    handleBtnAddModalAddSuccess() {
      this.showModalAddSuccess = false;
      this.showModal = {
        isShow: true,
        data: null,
        id: null,
      }
      this.username = "";
      this.password = "";
      this.email = "";
      this.userGroup = "";
      this.site = [];
    },
    handleTurnOffModalEdit() {
      this.showModal = {
        isShow: false,
        data: null,
        id: null,
      };
    },
    handleTurnOnModalEditSuccess() {
      this.showModalEditSuccess = true;
    },
    handleTurnOffModalEditSuccess() {
      this.showModalEditSuccess = false;
    },
    handleSearch() {
      this.page = 1
      this.fetchData();
    },
    onChangePage(page) {
      this.page = page
      this.fetchData({ page });
    },
    handleSort({ sort_filter, sort }) {
      this.data_sort = sort.join(',')
      this.data_sort_filter= sort_filter.join(',')
      this.fetchData();
    }
  },
};
</script>

<style scoped lang="scss">
@import "./userManager.scss";
</style>
