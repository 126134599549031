import { ERROR_MESSAGES } from '@/constants/error-messages';
import axios from 'axios';
import Vue from 'vue';
Vue.prototype.$axios = axios;

const baseURL = `${process.env.VUE_APP_BASE_API_URL}/api/v2`;

const axiosClient = axios.create({
  baseURL,
});

const axiosClientAuth = axios.create({
  baseURL,
});

// Add a auth request interceptor
axiosClientAuth.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Vue.$toast.error(error?.response?.data?.message, {
      position: 'bottom-left',
    });
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClientAuth.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.error_code === 'error_106' || error?.response?.data?.error_code === 'error_097') {
      window.location.href = "/login"
      localStorage.clear();
    }
    Vue.$toast.error(ERROR_MESSAGES[error?.response?.data?.error_code], {
      position: 'bottom-left',
    });
    return Promise.reject(error);
  }
);

function httpGet(url) {
  return axiosClient.get(url);
}

function httpPost(url, data) {
  return axiosClient.post(url, data);
}

function httpPut(url, data) {
  return axiosClient.put(url, data);
}

function httpPatch(url, data) {
  return axiosClient.patch(url, data);
}

function httpDelete(url) {
  return axiosClient.delete(url);
}

function httpGetAuth(url, params = {}) {
  return axiosClientAuth.get(url, { params });
}

function httpPostAuth(url, data) {
  return axiosClientAuth.post(url, data);
}

function httpPutAuth(url, data) {
  return axiosClientAuth.put(url, data);
}

function httpPatchAuth(url, data) {
  return axiosClientAuth.patch(url, data);
}

function httpDeleteAuth(url) {
  return axiosClientAuth.delete(url);
}

export {
  baseURL,
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
  httpPatch,
  httpGetAuth,
  httpPostAuth,
  httpPutAuth,
  httpPatchAuth,
  httpDeleteAuth,
};
