import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from './modules/auth';
import userManager from './modules/user-manager';
import roleManage from './modules/role-manage';
import common from './modules/common';

import loading from './modules/loading';
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ accessible_menus: state.common.accessible_menus }),
  key: "access_menu"
});

const vuexLocalRole = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => state.common.role,
  key: "role"
})

export default new Vuex.Store({
  modules: {
    auth,
    userManager,
    roleManage,
    loading,
    common
  },
  plugins: [vuexLocal.plugin, vuexLocalRole.plugin]
});