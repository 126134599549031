import VueRouter from 'vue-router';
import Vue from 'vue';
import LoginView from "@/views/LoginView.vue";
import HomeLayout from "@/layout/HomeLayout.vue";
import NotFoundLayout from "@/layout/NotFoundLayout.vue";
import UserManagementView from '@/views/UserManagement/UserManagementView.vue';
import DashboardViewVue from '@/views/DashboardView.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeLayout,
    children: [
      {
        path: "/user-manager",
        name: "user-manager",
        component: UserManagementView,
      },
      {
        path: "/",
        name: "dashboard",
        component: DashboardViewVue,
      },
    ]
  },
  {
    path: "/not-found",
    name: "not-fount",
    component: NotFoundLayout,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access_token")) {
        next('/');
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("access_token");

  if (authRequired && !loggedIn) {
    next({
      path: "/login",
    });
  }
  else if (!router.getRoutes().some((route) => route.path === to.path)) {
    next({
      path: '/not-found',
    });
  } else next();
});

export default router;
