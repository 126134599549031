<template>
  <div v-if="isLogin && showHeader" class="header-bar">
    <div class="image-logo col-3">Panasonic</div>

    <nav class="nav-bar col-3">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/blog">Blog</router-link> -->
      <router-link
        v-for="(item, index) in getItemRouter"
        :key="index"
        :to="item.path"
      >
        <img
          :src="currentRouteName === item.path ? item.imgActive : item.img"
          alt=""
          v-if="item.isShow"
        />
      </router-link>
    </nav>
    <div class="time-and-logout col-4">
      <div class="time">{{ timeZone }}</div>
      <button @click="handleLogout">
        <img src="../assets/icon-logout.png" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

import {
  // ICON_GLOBAL,
  // ICON_GLOBAL_ACTIVE,
  // ICON_HOME,
  // ICON_HOME_ACTIVE,
  // ICON_CHART,
  // ICON_CHART_ACTIVE,
  // ICON_ENERGY,
  // ICON_ENERGY_ACTIVE,
  // ICON_WARNING,
  // ICON_WARNING_ACTIVE,
  // ICON_CLOCK,
  // ICON_CLOCK_ACTIVE,
  ICON_MANAGEMENT_USER,
  ICON_MANAGEMENT_USER_ACTIVE,
} from '../theme/image.js';
import { displayRealTime } from '@/utils/common.js';

export default {
  props: {
    accessibleMenus: {
      type: Object,
    },
    getterRole: {
      type: String,
    },
  },

  data() {
    return {
      timeZone: '',
    };
  },
  mounted() {
    this.timer = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('auth', ['SET_IS_LOGIN']),
    handleLogout() {
      this.logout();
      localStorage.removeItem('access_token');
      localStorage.removeItem('role');
      localStorage.removeItem('access_menu');
      this.$router.push('/login');
    },
    updateTime() {
      this.timeZone = displayRealTime();
    },
  },
  created() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.SET_IS_LOGIN(true);
    }
    this.updateTime();
  },
  computed: {
    ...mapState('auth', ['isLogin']),
    ...mapGetters('common', {
      getterAccessibleMenus: 'getterAccessibleMenus',
    }),
    currentRouteName() {
      return this.$route.path;
    },
    showHeader() {
      return this.$route.path !== '/login';
    },
    getItemRouter() {
      return [
        // {
        //   path: '/dashboard',
        //   img: ICON_GLOBAL,
        //   imgActive: ICON_GLOBAL_ACTIVE,
        // },
        // {
        //   path: '/',
        //   img: ICON_HOME,
        //   imgActive: ICON_HOME_ACTIVE,
        // },
        // {
        //   path: '/',
        //   img: ICON_CHART,
        //   imgActive: ICON_CHART_ACTIVE,
        // },
        // {
        //   path: '/',
        //   img: ICON_ENERGY,
        //   imgActive: ICON_ENERGY_ACTIVE,
        // },
        // {
        //   path: '/',
        //   img: ICON_WARNING,
        //   imgActive: ICON_WARNING_ACTIVE,
        // },
        // {
        //   path: '/',
        //   img: ICON_CLOCK,
        //   imgActive: ICON_CLOCK_ACTIVE,
        // },
        {
          path: '/user-manager',
          img: ICON_MANAGEMENT_USER,
          imgActive: ICON_MANAGEMENT_USER_ACTIVE,
          isShow: this.accessibleMenus?.user?.view,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  align-items: center;
  display: flex;
  height: 56px;
  background: #1c1c1e;
  color: #fff;
  justify-content: space-between;
  padding: 0px 20px;

  .nav-bar {
    display: flex;
    justify-content: center;
  }

  .image-logo {
    display: flex;
    justify-content: start;
    font-size: 30px;
    font-weight: bold;
  }

  .time-and-logout {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
    .time {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: right;
      color: #8e8e93;
      text-transform: uppercase;
    }
  }
}
</style>
