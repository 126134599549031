<template>
  <div class="user-management-view">
    <div class="display-flex justify-end">
      <div class="display-flex mt-12 gap-6">
        <button v-if="userGroupVisibility" class="button-tab" :class="{ active: currentComponent === 'roles'}" @click="showComponent('roles')">user group management</button>
        <button class="button-tab" :class="{ active: currentComponent === 'users'}" @click="showComponent('users')">user management</button>
      </div>
    </div>
    <div v-if="currentComponent === 'users'">
      <UserManageView />
    </div>
    <div v-else-if="currentComponent === 'roles'">
      <RolesManageView />
    </div>
  </div>
</template>

<script>
import RolesManageView from '../RoleManage/RolesManageView.vue';
import UserManageView from '../UserManager/UserManageView.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'UserManagementView',
  components: {
    UserManageView,
    RolesManageView,
  },
  data() {
    return {
      currentComponent: 'users',
      isShowGroupUser: true,
    } 
  },
  methods: {
    showComponent(componentName) {
      this.currentComponent = componentName;
    },
  },
  computed: {
    ...mapGetters("common", {
      getterAccessibleMenus: "getterAccessibleMenus",
      getterRole: "getterRole"
    }),
    userGroupVisibility() {
      return this.getterRole === 2;
    }
  },
};
</script>

<style scoped lang="scss">
@import "./userManagement.scss";
</style>
