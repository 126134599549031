<template>
   <div class="dashboard">
    <div class="title">This is an dashboard page</div>
  </div>
</template>

<script>
export default {
  name: "DashboardView"
}
</script>

<style scoped>
.title {
  color: #FFF;
  font-size: 18px;
}
</style>
