<template>
  <div class="body" data-app>
    <router-view></router-view>
    <LoadingComponent />
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent.vue';
export default {
  components: {
    LoadingComponent,
  },
};
</script>

<style>

@font-face {
  font-family: 'PUDSansserifM';
  src: local("PUDSansserifM"),
    url('./assets/fonts/PUDSansserifM.ttf');
}

@font-face {
  font-family: 'PUDSansserifB';
  src: url('./assets/fonts/PUDSansserifB.ttf');
}

@font-face {
  font-family: 'PUDSansserifR';
  src: url('./assets/fonts/PUDSansserifR.ttf');
}

body {
  background-color: #3d3d3d !important;
}



.body {
  background: black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  font-family: 'PUDSansserifM';
  max-width: 100%;
  width: 2500px;
  margin: 0 auto;
}

</style>
